<template>
  <div class="warning-row">
    <!-- 一条预警 -->
    <div class="one-warning" v-for="item in source.data" :key="item.id">
      <!-- 图片 -->
      <div class="img">
        <img @click="showPicture(item.warningPhotoStorage.substring(1,item.warningPhotoStorage.length-1).split(','))" :src="item.warningPhotoStorage.substring(1,item.warningPhotoStorage.length-1).split(',')[0]" alt="">
      </div>
      <!-- info -->
      <div class="info-decorate">
        <span class="text-decorate"></span>
        所属任务：<span class="detail-text" :title="item.taskTitle">{{item.taskTitle}}</span>
      </div>
      <div class="info-decorate">
        <span class="text-decorate"></span>
        预警类型：<span class="detail-text">{{item.warningTypeName}}</span>
      </div>
      <div class="info-decorate">
        <span class="text-decorate"></span>
        预警时间：<span class="detail-text">{{item.warningTime}}</span>
      </div>
      <div class="info-decorate">
        <span class="text-decorate"></span>
        任务范围：<span class="detail-text">{{item.destination}}-{{item.origin}}</span>
      </div>
      <div class="info-decorate">
        <span class="text-decorate"></span>
        飞手信息：<span class="detail-text">{{item.flightUser}}  {{item.flightPhone}}</span>
      </div>
      <div class="info-btn"><span class="linear-btn" @click="openAreaPopup(item)">位置信息</span></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: Object,
      default() {
        return {}
      }
    },
    extraProps:{
      type: Object,
      default() {
        return {}
      }
    },
    showPicture: {
      type: Function,
      default(){
        return ()=>{}
      }
    },
    openAreaPopup: {
      type: Function,
      default(){
        return ()=>{}
      }
    }
  },
  data() {
    return { 
    };
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.warning-row{
  width: 1330px;
  display: flex;
  justify-content: space-between;
  .one-warning{
    width: 438px;
    height: 506px;
    border: 1px solid #009aff;
    padding: 12px;
    box-sizing: border-box;
    margin-bottom: 12px;
    .img{
      width: 100%;
      height: 235px;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .info-decorate{
      margin-top: 8px;
      padding-left: 20px;
      width: 100%;
      height: 32px;
      background: linear-gradient(270deg, rgba(36,96,167,0) 0%, rgba(36,96,167,0.4300) 100%);
      font-size: 16px;
      box-sizing: border-box;
      line-height: 32px;
      font-family: MicrosoftYaHei;
      color: #AACAFF;
      position: relative;
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      .text-decorate{
        position: absolute;
        border-radius: 50%;
        top: 14.5px;
        left: 8px;
        width: 3px;
        height: 3px;
        background: #FFFFFF;
        box-shadow: 0px 0px 7px 2px rgba(39,155,255,0.8500);
      }
      .detail-text{
        color: #fff;
      }
    }
    .info-btn{
      margin-top: 10px;
      width: 98px;
      float: right;
    }
  }
}
</style>