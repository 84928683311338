<template>
  <div class="warning">
    <!-- 区域展示弹框 -->
    <div v-if="earlyWarningPoint" class="area-select-popup">
      <div class="content">
        <div class="button">
          <el-button @click="earlyWarningPoint = null" type="primary">关闭</el-button>
        </div>
        <Drawky
          :v-if="earlyWarningPoint"
          :airspcelist="areaPointListObj"
          :earlyWarningPoint="earlyWarningPoint"
          :readonly="true"
        />
      </div>
    </div>
    <div class="warning-center">
      <Box>
        <div class="warnbody">
          <!-- 搜索 -->
          <div class="search">
            <el-form
              :model="search_form"
              class="linear-form"
              inline
            >
              <el-form-item label="预警类型:">
                <el-select v-model="search_form.warningTypeId" size="mini" clearable  placeholder="请选择预警类型" style="padding-right: 0px;">
                  <el-option
                    v-for="item in warningType"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="时间选择:">
                <el-date-picker
                  v-model="search_form.date"
                  type="daterange"
                  range-separator="至"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-form>
            <div class="linear-btn"  @click="async_get_list" style="margin-left:20px">搜索</div>
          </div>
          <VirtualList
            class="content"
            style="overflow-y: auto;"
            :extra-props="{openAreaPopup,showPicture}"
            :data-key="'id'"
            :keeps="4"
            :data-sources="list"
            :data-component="oneWarningCom"
            :estimate-size="520"
          />
        </div>
      </Box>
    </div>
  </div>
</template>

<script>
import VirtualList from 'vue-virtual-scroll-list'  // 无限滚动插件
import oneWarning from './one-warning.vue' // 一行预警信息
import Box from "@/components/platform/common/box";
import Drawky from "@/components/drawky"; // 绘制区域的
import API from "@/api";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);
export default {
  components: {
    Box,
    Drawky,
    VirtualList,
    oneWarning
  },
  data() {
    return { 
      oneWarningCom: oneWarning,
      areaPointListObj: null, // 存储绘制区域的信息
      earlyWarningPoint: null, // 预警点信息
      search_form: { // 搜索参数
        warningTypeId:null,
        date: null
      },
      warningType: [], // 预警下拉信息
      list:[], // 请求回来的数据
      img: null,// 存储点击图片的链接数组
    };
  },
  methods: {
    openAreaPopup(item) {  // 查看区域弹框
      if(item.locationArea){
        this.areaPointListObj = {regionList:null}
        let araeArr = []
        araeArr = item.locationArea.split(' ')
        let lastAraeArr = []
        araeArr.forEach(item=>{
          let areaPoint = item.split(',')
          lastAraeArr.push(areaPoint[0]*1)
          lastAraeArr.push(areaPoint[1]*1)
        })
        this.areaPointListObj.regionList = lastAraeArr
      } else {
        this.areaPointListObj = {regionList:null}
      }
      this.earlyWarningPoint = item.warningPlace
    },
    showPicture(urls) { // 点击展示图片
        this.img = urls
        let images = []
        urls.forEach(item => {
          images.push(item.trim())
        });
        this.$viewerApi({
          images: images,
        })

    },
    async async_get_list() { // 获取数据
      let obj = {
        warningTypeId: null,
        startTime: null,
        endTime: null,
      }
      obj.warningTypeId = this.search_form.warningTypeId
      obj.startTime = this.search_form.date?this.search_form.date[0]:''
      obj.endTime = this.search_form.date?this.search_form.date[1]:''
      try {
        const res = await API.AI.GetHistoricalarningList(obj);
        let lastArr = []
        for(let i in res.data){
          if((i+1)%3 === 0){
            lastArr.push({data:[res.data[i-2],res.data[i-1],res.data[i]],id:res.data[i-2].id})
          }
          if(~~(res.data.length/3)*3 != res.data.length && i == ~~(res.data.length/3)*3){
            lastArr.push({id:-999,data:[]})
            for(let li = i; li<res.data.length; li++){
              lastArr[lastArr.length - 1],data.push(res.data[li])
            }
          }
        }
        this.list = lastArr
      } catch (e) {
        console.error(e);
      }
    },
    async getWarningType() {
      const res = await API.TASK.getAlertTypeDropDown()
       
      this.warningType = res.data
    }
  },
  mounted() {
    this.getWarningType()
    this.async_get_list()
  },
  watch: {
    img: function(newVal, oldVal) {
      if(newVal.length === 1) {
        let prev = document.getElementsByClassName('viewer-prev')[0]
        let next = document.getElementsByClassName('viewer-next')[0]
        prev.style.display   =   "none";   //隐藏而不删除  
        next.style.display   =   "none";   //隐藏而不删除
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep{
  .area-select-popup{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(12, 34, 67, 0.5);
    z-index: 7000;
    .content{
      position: absolute;
      width: 1600px;
      height: 800px;
      left: 50%;
      top: 100px;
      margin-left: -800px;
      border: 1px solid #009aff;
      .button{
        position: absolute;
        top: 20px;
        right: 20px;
        width: 80px;
        z-index: 1000;
        .el-button--primary{
          background-color: #69b3e5;
        }
      }
    }
  }
}
.search ::v-deep {
  .el-input{
    width: 174px;
    .el-input__inner{
      width: 100% !important;
    }
  }
  .el-date-editor{
    .el-range__close-icon{
      margin-left: -20px;
    }
  }
}
.warning {
  width: 100%;
  height: 926px;
  display: flex;
  justify-content: center;
  &-center {
    width: 1400px;
    padding-top: 35px;
    .warnbody {
      width: 1400px;
      height: 100%;
      .search {
        padding-bottom: 30px;
        display: flex;
        .input-with-name {
          width: 347px;
          height: 36px;
          line-height: 36px;
        }
      }
      .no-data{
        width: 100%;
        height: 94%;
      }
      .content{
        overflow: auto;
        width: 1340px;
        height: 94%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-right: 10px;
        &::-webkit-scrollbar{width:6px;}
      }
    }
  }
  ::v-deep .cpt-warning-slot-box {
    width: 300px;
    height: 200px;
    border: none;
  }
}
</style>
<style lang="scss" >
.seach .input-with-name .el-input__inner,
.seach .input-with-name .el-input-group__append {
  box-sizing: border-box;
  background: rgba(12, 34, 67, 0.5);
  border: 1px solid #009aff;
  border-radius: 0;
  font-size: 16px;
  color: #9fc4ff;
  letter-spacing: 0;
  font-weight: 400;
  height: 39px;
  line-height: 36px;
}
.seach .input-with-name .el-input__inner {
  border-right: none;
}
.seach .input-with-name .el-input-group__append {
  border-left: none;
}

.action-route-dropdown-list .pointer:hover{
      color: #35A2FF;
    }
</style>